<template>
    <div
        class="rounded-3xl flex flex-col"
        :class="{ 'md:w-[500px] gap-10 p-6': cardType === 'dialog', 'bg-white  border-2 border-[#E8E8E8] relative m-4 gap-10 p-6': cardType === 'inChat' }">
        <!-- Insight gradient -->
        <div
            class="w-full px-6 md:px-10 md:py-10 py-3 flex flex-col gap-2 insight-content z-0"
            :class="{ 'max-w-[400px] md:h-[300px] !py-10 mx-auto bg-white': cardType === 'onboarding', gradient: cardType !== 'onboarding' }">
            <div class="flex flex-row items-center gap-6">
                <!-- Lighting Icon -->
                <i class="bi bi-lightning-charge-fill text-xl text-[#555BA2]"></i>
                <div class="body-1 font-semibold text-[#555BA2]">{{ title }}</div>
            </div>
            <div class="heading-3 text-[#262626] mt-4 w-full">{{ subtitle }}</div>
            <div v-if="cardType === 'onboarding'" class="flex flex-row w-full justify-between items-center md:mt-auto mt-4">
                <BaseButton theme="secondary" @click="$emit('seeMore')"><p class="text-[#555BA2]">See more</p></BaseButton>
                <BaseButton theme="primary" class="w-[120px] leading-6 body-1 whitespace-nowrap" type="submit" @click="$emit('diveDeeper')">Dive deeper</BaseButton>
            </div>
        </div>
        <template v-if="isDialog">
            <div class="md:overflow-y-auto md:max-h-64 body-1 text-[#8C8C8C]">{{ content }}</div>
            <div class="flex flex-col items-center gap-3">
                <BaseButton theme="primary" class="w-fit leading-6 body-1" type="submit" @click="$emit('diveDeeper')">Dive deeper</BaseButton>
                <BaseButton theme="secondary" class="w-fit leading-6 body-1 whitespace-nowrap text-[#555BA2]" @click="$emit('close')">Dismiss</BaseButton>
            </div>
        </template>
        <div v-if="cardType === 'inChat'" class="md:overflow-y-auto md:max-h-64 body-1 text-[#8C8C8C]">{{ content }}</div>
    </div>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import { computed, defineEmits, defineProps } from "vue";

const props = defineProps({
    cardType: String,
    title: String,
    subtitle: String,
    content: String,
    id: String,
});

const isDialog = computed(() => props.cardType === "dialog");
defineEmits(["close", "diveDeeper", "seeMore"]);
</script>

<style scoped>
/*
 * Getting a gradient rounded border with a semi-transparent gradient background is not trivial.
 * This solution was taken from https://stackoverflow.com/a/66936639 and seems to be compatible
 * across browsers.
 */
.insight-content {
    position: relative;
    background-clip: padding-box;
    border-radius: 24px;
}

.insight-content.gradient {
    background: linear-gradient(125deg, rgba(255, 40, 145, 0.04) -22.98%, rgba(0, 63, 220, 0.04) 113.56%);
}

.insight-content::before {
    position: absolute;
    padding: 2px;
    inset: 0;
    z-index: -1;
    background: linear-gradient(125deg, #ff2891 -22.98%, #003fdc 113.56%);
    border-radius: 24px;
    content: "";
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
</style>
